/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_PRETEST_REQUEST } from "../../_reducers/pretestAction";
import { ModalTemplate } from "../../components/base/ModalTemplate";
import { Inquire } from "../../components/inquire/Inquire";
import { BasicButton } from "../../components/system/button/BasicButton";
import { CheckMaskingGuide } from "../../components/idcard/CheckMaskingGuide";
import { Dropzone } from "../../components/system/Dropzone";
import { practiceApi } from "../../api/practice/practiceApi";
import { Canvas } from "../../components/idcard/Canvas";
import { SubRectangleButton } from "../../components/system/button/SubRectangleButton";
import { RecButton } from "../../components/system/button/RecButton";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ALERT_MODAL_ON } from "../../_reducers/modalAction";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { convertURLtoFile } from "../../utils/convert";

export const PretestIdcardPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false); //등록 로딩
  const [select, setSelected] = useState();

  const [nextCheckModal, setNextCheckModal] = useState(false);
  const [dropzoneImg, setDropzoneImg] = useState(false);
  const [mergeFile, setMergeFile] = useState(); //합쳐진 file
  const { testerIdx, groupIdx } = useSelector((state) => state.userAction.data);
  const drawRef = useRef(null); //드로잉 ref

  const [confirmModal, setConfirmModal] = useState(false);
  const [uploadUrl, setUploadUrl] = useState("");

  //다음 페이지로 이동
  const nextPage = () => {
    navigate("/test/pretest", { replace: true });
    //사전 점검 데이터
    dispatch({
      type: SET_PRETEST_REQUEST,
      data: {
        examNo: 1,
        examSubNo: 1,
        pageNo: 1,
        mode: "MOVE",
      },
    });
  };

  //다시 선택
  const backState = () => {
    setDropzoneImg(false);
    setModal(false);
  };

  const sendIdCard = async () => {
    setLoading(true);

    //합쳐진(신분증 이미지 + 드로잉) data url을 파일로 변환
    const imageFile = mergeFile;
    const config = {
      header: { "content-type": "multipart/form-data" },
    };
    let formdata = new FormData();
    formdata.append("siteName", process.env.REACT_APP_CON_SITEID);
    formdata.append("groupIdx", parseInt(groupIdx));
    formdata.append("idCardName", `${parseInt(testerIdx)}_1.jpg`);
    formdata.append("file", imageFile);

    await axios
      .post(
        "https://" + process.env.REACT_APP_CON_WEBRTC_SERVER + "/upload/IdCard",
        formdata,
        config
      )
      .then((response) => {
        setUploadUrl(response.data.save_url);
        setConfirmModal(true);
        setLoading(false);
      })
      .catch((error) => {
        dispatch({
          type: ALERT_MODAL_ON,
          data: t(`error.system`),
        });
        setLoading(false);
        backState();
      });
  };

  const language = i18n.language;

  return (
    <>
      {confirmModal && (
        <ModalTemplate closeBtn={true} setModal={setConfirmModal}>
          <div css={dropzoneConfirmModalWrap}>
            <div className="image-wrap">
              <div>
                {dropzoneImg &&
                  dropzoneImg?.map((file) => (
                    <div key={file.name}>
                      <div style={{ position: "relative" }}>
                        <div css={imgBottom}>
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{
                              fontSize: "25px",
                              paddingRight: "15px",
                              color: "red",
                            }}
                          />
                          {t(`idCard.pretest.alert.noImage`)}
                        </div>
                        <img css={img} src={uploadUrl} alt="" />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="notice-text">
                <span> {t(`idCard.pretest.alert.noticeText1`)} </span>
                <span> {t(`idCard.pretest.alert.noticeText2`)}</span>
              </div>
            </div>
            <div className="next-modal">
              {t(`idCard.pretest.alert.nextModalText`)}
            </div>
          </div>

          <div css={confirmModalButtonWrap}>
            <RecButton
              wid="s"
              hei="s"
              onClick={() => {
                practiceApi.uploadIdcard(uploadUrl, select.type).catch(() => {
                  backState();
                });
                setConfirmModal(false);
                setNextCheckModal(true);
              }}
              label={t(`idCard.pretest.button.confirm`)}
            />
          </div>
        </ModalTemplate>
      )}
      <div css={totalWrapper}>
        {/* 업로드가 안됐을 경우 */}
        {modal && !dropzoneImg ? (
          <ModalTemplate setModal={setModal} isTest={true} closeBtn={true}>
            <p css={messageText}>{t(`idCard.pretest.notUpload`)}</p>
          </ModalTemplate>
        ) : null}
        <div css={contentWrapper}>
          <div css={guideWrap}>
            <CheckMaskingGuide setSelected={setSelected} select={select} />
          </div>
          <div css={uploadWrapper}>
            {!dropzoneImg && (
              <div css={imageGuide({ language })}>
                {t(`idCard.pretest.title`)}
              </div>
            )}
            <div css={imageDrawWrap}>
              {!dropzoneImg && (
                <>
                  <Dropzone setDropzoneImg={setDropzoneImg} />
                  <div css={noticeGuide}>
                    {t(`idCard.pretest.noticeUploadGuide`)}
                  </div>
                </>
              )}
              {dropzoneImg && (
                <>
                  <div className="sub-guide-text">
                    {t(`idCard.pretest.dragGuide`)}
                  </div>
                  <div css={canvasWrapper}>
                    <Canvas
                      url={dropzoneImg[0]?.preview}
                      ref={{ drawRef: drawRef }}
                      setMergeFile={setMergeFile}
                    />
                  </div>
                  <div className="sub-button-wrapper">
                    <SubRectangleButton
                      className="erase-btn"
                      label={t(`idCard.pretest.button.resetMasking`)}
                      ref={drawRef}
                      onClick={async () => {
                        var ctx = drawRef.current.getContext("2d");
                        ctx.clearRect(0, 0, 1000, 1000);

                        const convertFile = await convertURLtoFile(
                          dropzoneImg[0]?.preview
                        );
                        setMergeFile(convertFile);
                      }}
                    />

                    <SubRectangleButton
                      label={t(`idCard.pretest.button.reSelect`)}
                      onClick={backState}
                    />
                  </div>
                </>
              )}
            </div>
            {/* 제출 버튼 */}
            {(() => {
              if (dropzoneImg) {
                if (loading) {
                  return (
                    <div css={buttonWrapper}>
                      <RecButton wid="s" hei="s">
                        <FontAwesomeIcon icon={faSpinner} />
                        {t(`idCard.pretest.button.uploading`)}
                      </RecButton>
                    </div>
                  );
                } else {
                  return (
                    <div css={buttonWrapper}>
                      <RecButton
                        wid="s"
                        hei="s"
                        label={t(`idCard.pretest.button.submitIdCard`)}
                        onClick={sendIdCard}
                      />
                    </div>
                  );
                }
              }
            })()}
            {/* 사전점검 시작 전 확인 모달 */}
            {nextCheckModal ? (
              <ModalTemplate setModal={setModal} isTest={true}>
                <p css={messageText}>{t(`idCard.pretest.uploadComplete`)}</p>
                <BasicButton
                  wid="s"
                  hei="s"
                  onClick={nextPage}
                  label={t(`idCard.pretest.button.confirm`)}
                />
              </ModalTemplate>
            ) : null}
          </div>
        </div>
        <Inquire />
      </div>
    </>
  );
};

const dropzoneConfirmModalWrap = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .notice-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 300px;
    height: 200px;
    margin-left: 20px;
    margin-top: 20px;
  }
  .image-wrap {
    display: flex;
    flex-direction: row;
  }
  .next-modal {
    margin-top: 20px;
  }
`;
const totalWrapper = css`
  display: flex;
  height: 90%;
  align-items: center;
`;

const noticeGuide = css`
  padding-top: 10px;
  color: #ff004e;
  text-align: left;
  white-space: pre;
`;
const imageGuide = ({ language }) => css`
  position: relative;
  text-align: center;
  top: 18px;
  font-size: ${language === "KOR" ? "20px" : "14px"};
  margin-bottom: ${language === "KOR" ? "" : "47px"};
  white-space: pre;
`;
const imageDrawWrap = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  .sub-button-wrapper {
    position: relative;
    top: 190px;
    display: flex;
    width: 400px;
    justify-content: space-between;
  }
  .sub-guide-text {
    position: relative;
    bottom: 157px;
    font-size: 20px;
  }
`;

const uploadWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 570px;
  background-color: #efefef;
  margin: 0 8px;
  white-space: pre-line;
  text-align: center;
  height: 100%;
  max-height: 520px;
`;

const canvasWrapper = css`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 37px;
`;

const contentWrapper = css`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: space-around;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const guideWrap = css`
  width: 480px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 0 8px;
  height: 100%;
  max-height: 520px;
`;

const buttonWrapper = css`
  position: relative;
  bottom: 20px;
`;

const messageText = css`
  position: relative;
  white-space: pre;
`;

const imgBottom = css`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  width: 100%;
  height: 100%;
  min-width: 250px;
  max-width: 250px;
  min-height: 200px;
  max-height: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid;
`;
const img = css`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  min-width: 250px;
  max-width: 250px;
  min-height: 200px;
  max-height: 200px;
  margin-bottom: 30px;
  border: 1px solid;
`;

const confirmModalButtonWrap = css`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
`;
