import produce from "immer";
import { PURGE } from "redux-persist";

export const initialState = {
  completer: null, //사전점검 완료자 인지 아닌지 사전 점검면 false , 본검사면 true
  networkSpeed: null, //응시자의 네트워크속도
  data: {},
};
export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_COMPLETER_REQUEST = "USER_COMPLETER_REQUEST";
export const USER_NETWORK_SPEED = "USER_NETWORK_SPEED";

const userReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case USER_DATA_REQUEST:
        draft.data = action.data;
        break;
      case USER_COMPLETER_REQUEST:
        draft.completer = action.data;
        break;
      case USER_NETWORK_SPEED:
        draft.networkSpeed = action.data;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        break;
    }
  });

export default userReducer;
