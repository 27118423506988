/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { RecButton } from "../../components/system/button/RecButton";
import { Inquire } from "../../components/inquire/Inquire";
import { API_URL } from "../../data/api";
import {
  faArrowUpFromBracket,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SubRectangleButton } from "../../components/system/button/SubRectangleButton";
import { useInterval } from "../../hooks/useInterval";
import apiClient from "../../api/apiClient";
import { useTranslation } from "react-i18next";

export const PretestSnapshotPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [hasPhoto, setHasPhoto] = useState(false);
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(5);
  const [isRunning, setIsRunning] = useState(false);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const { testerIdx, groupIdx } = useSelector((state) => state.userAction.data);

  const { video, audio } = useSelector((state) => state.deviceAction);

  const [currentCamState, setCurrentCamState] = useState({
    camHeight: 427,
    camWidth: 320,
  });

  const getWebcam = (callback) => {
    const initialConstraints = {
      audio: {
        deviceId: audio,
      },
      video: {
        deviceId: video,
        width: 320,
        height: 427,
        frameRate: 5,
      },
    };
    try {
      navigator.mediaDevices.getUserMedia(initialConstraints).then(callback);
    } catch (err) {
      alert(
        "웹캠을 불러오지 못 하였습니다. 다른 장치에서 사용중이 아닌지 확인해 주세요."
      );
      console.log(err);
      return undefined;
    }
  };

  useEffect(() => {
    getWebcam((stream) => {
      const videoTrack = stream.getVideoTracks()[0];
      const settings = videoTrack.getSettings();
      const { width, height } = settings;

      if (width < 320 || height < 427) {
        setCurrentCamState({
          camHeight: height,
          camWidth: width,
        });
      }
      videoRef.current.srcObject = stream;
    });
  }, [hasPhoto]);

  //캡쳐
  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    canvas.width = currentCamState.camWidth;
    canvas.height = currentCamState.camHeight;

    const ctx = canvas.getContext("2d");

    //캡쳐된 화면 자르기
    ctx.drawImage(
      video,
      0,
      0,
      currentCamState.camWidth,
      currentCamState.camHeight,
      0,
      0,
      currentCamState.camWidth,
      currentCamState.camHeight
    );
    setHasPhoto(true);
  };

  useInterval(
    () => {
      setCount(count - 1);
      if (count === 1) {
        setIsRunning(false);
        setCount(5);
        takePhoto();
      }
    },
    isRunning ? 1000 : null
  );

  const pretestSnapShotUpload = () => {
    setLoading(true);
    const dataUrl = canvasRef.current.toDataURL();
    const data = {
      siteName: process.env.REACT_APP_CON_SITEID,
      groupIdx: groupIdx,
      idCardName: testerIdx + "_3.png",
      idCardFile: dataUrl,
    };
    apiClient
      .post(
        "https://" +
          process.env.REACT_APP_CON_WEBRTC_SERVER +
          "/upload/snapShot",
        data
      )
      .then((response) => {
        apiClient
          .patch(`${API_URL.PRACTICE_SCREEN}`, {
            testerScreenUrl: response.data.save_url,
          })
          .then((res) => {
            navigate("/test/pretest/idcard", { replace: true });
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div css={totalWrapper}>
      <div css={guideWrapper}>
        <div css={guideBox} />
        <div css={title}>{t(`identification.text`)}</div>
      </div>
      <div css={container}>
        <div css={captureBox({ currentCamState })}>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            style={{
              transform: "rotateY(180deg)",
              display: hasPhoto && "none",
            }}
          />
          <canvas
            ref={canvasRef}
            style={{
              display: !hasPhoto ? "none" : "flex",
              transform: "rotateY(180deg)",
            }}
          />
        </div>
        <div css={btnWrapper}>
          {!hasPhoto ? (
            <RecButton
              onClick={() => {
                setIsRunning(true);
              }}
            >
              {!isRunning && <FontAwesomeIcon icon={faCamera} />}
              {isRunning
                ? `${count}${t(`idCard.button.seconds`)}`
                : t(`idCard.button.takePhoto`)}
            </RecButton>
          ) : (
            <>
              <div className="canvas-btn">
                <SubRectangleButton
                  onClick={() => setHasPhoto(false)}
                  label={t(`idCard.button.rePhoto`)}
                >
                  {t(`idCard.button.rePhoto`)}
                </SubRectangleButton>
              </div>
              {loading ? (
                <RecButton>
                  <FontAwesomeIcon icon={faSpinner} />
                  {t(`idCard.button.registering`)}
                </RecButton>
              ) : (
                <RecButton onClick={() => pretestSnapShotUpload()}>
                  <FontAwesomeIcon icon={faArrowUpFromBracket} />
                  {t(`idCard.button.registration`)}
                </RecButton>
              )}
            </>
          )}
        </div>
      </div>
      <Inquire />
    </div>
  );
};
const captureBox = ({ currentCamState }) => css`
  width: 320px;
  height: 427px;
  display: flex;
  justify-content: center;
  align-items: center;
  canvas {
    display: flex;
    width: ${currentCamState.camWidth}px;
    height: ${currentCamState.camHeight}px;
  }
`;
const container = css`
  display: flex;
  flex-direction: column;
`;
const totalWrapper = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const guideWrapper = css`
  display: flex;
  flex-direction: column;
  width: 100;
  justify-content: center;
  align-items: center;
`;

const guideBox = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 427px;
  background-image: url("/images/preSnapshot.png");
  background-position: center;
  background-size: cover;
`;
const title = css`
  font-size: 18px;
  text-align: center;
  white-space: pre;
  span {
    font-weight: 700;
  }
  padding-top: 20px;
`;

const btnWrapper = css`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  .canvas-btn {
    position: absolute;
    right: 0;
    top: 8px;
  }
`;
