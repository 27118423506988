import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import RecordRTC from "recordrtc";
import { examApi } from "../api/exam/examApi";
import { getCurrentDate } from "../utils/getDateFormat";
import { socketId } from "../utils/socketId";
import { useCreateMediaStream } from "./useCreateMediaStream";
import { SET_DEVICE_CAM_STREAM } from "../_reducers/deviceAction";
import { useRef, useState } from "react";
import { useNowExamData } from "./useNowExamData";
import { useNavigate } from "react-router-dom";

//녹화 상태를 담을 배열
let recorder_video;
let recorder_screen;
let recordTitle = "";

export const useRecord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { testerIdx, roomIdx, screenShareUseYN } = useSelector(
    (state) => state.userAction.data
  );
  const { getWebcam } = useCreateMediaStream();
  const { videoStream, screenStream } = useSelector(
    (state) => state.deviceAction
  );
  const { myRoomId, myPeerId } = socketId(
    roomIdx,
    testerIdx,
    process.env.REACT_APP_CON_SITEID
  );
  const nowExamData = useNowExamData();
  const examSubStatusRef = useRef(null);

  /** 웹캠 파일 저장 */
  const postFilesVideo = async () => {
    try {
      const d = getCurrentDate(new Date());
      const fileName = `[${recordTitle}]${myPeerId}_video_${d}.webm`;
      const blob = recorder_video.getBlob();
      const file = new File([blob], fileName, {
        type: "video/webm",
      });

      const formdata = new FormData();
      formdata.append("videoBlob", file, fileName);
      formdata.append("siteName", process.env.REACT_APP_CON_SITEID);
      formdata.append("peerId", myPeerId);
      formdata.append("roomId", myRoomId);

      const response = await axios.post(
        "https://" + process.env.REACT_APP_CON_WEBRTC_SERVER + "/upload/record",
        formdata
      );

      await examApi.examRecord("CAM", response.data.message);
      startCamRecordingHandler(videoStream);
    } catch (error) {
      startCamRecordingHandler(videoStream);
    }
  };

  const sendVideoFile = async () => {
    try {
      const d = getCurrentDate(new Date());
      const fileName = `[${recordTitle}]${myPeerId}_video_${d}.webm`;
      const blob = recorder_video.getBlob();
      const file = new File([blob], fileName, { type: "video/webm" });

      const formdata = new FormData();
      formdata.append("videoBlob", file, fileName);
      formdata.append("siteName", process.env.REACT_APP_CON_SITEID);
      formdata.append("peerId", myPeerId);
      formdata.append("roomId", myRoomId);

      const response = await axios.post(
        `https://${process.env.REACT_APP_CON_WEBRTC_SERVER}/upload/record`,
        formdata
      );

      await examApi.examRecord("CAM", response.data.message);
    } catch {}
  };
  /** 스크린 파일 저장 포기 조건없이 보냄*/
  const sendAllScreenWabCamFile = async () => {
    try {
      const d = getCurrentDate(new Date());
      const fileName = `[${recordTitle}]${myPeerId}_screen_${d}.webm`;
      const blob = recorder_screen.getBlob();
      const file = new File([blob], fileName, { type: "video/webm" });

      const formdata = new FormData();
      formdata.append("videoBlob", file, fileName);
      formdata.append("siteName", process.env.REACT_APP_CON_SITEID);
      formdata.append("roomId", myRoomId);
      formdata.append("peerId", myPeerId);

      const response = await axios.post(
        `https://${process.env.REACT_APP_CON_WEBRTC_SERVER}/upload/record`,
        formdata
      );

      await examApi.examRecord("SCREEN", response.data.message).then((res) => {
        //미아 방지를 위한 코드
        if (res.data.data.roomIdx !== roomIdx) {
          navigate("/logout");
        }
      });

      setLoading(false);
      recorder_video?.stopRecording(postFilesVideo);
      startScreenRecordingHandler(screenStream);
    } catch (error) {
      startScreenRecordingHandler(screenStream);
      setLoading("error");
    }
  };
  /** 스크린 파일 저장(포기 추가)*/
  const sendScreenWebCamFile = async () => {
    try {
      const d = getCurrentDate(new Date());
      const fileName = `[${recordTitle}]${myPeerId}_screen_${d}.webm`;
      const blob = recorder_screen.getBlob();
      const file = new File([blob], fileName, { type: "video/webm" });

      const formdata = new FormData();
      formdata.append("videoBlob", file, fileName);
      formdata.append("siteName", process.env.REACT_APP_CON_SITEID);
      formdata.append("roomId", myRoomId);
      formdata.append("peerId", myPeerId);

      const response = await axios.post(
        `https://${process.env.REACT_APP_CON_WEBRTC_SERVER}/upload/record`,
        formdata
      );

      await examApi.examRecord("SCREEN", response.data.message).then((res) => {
        //미아 방지를 위한 코드
        if (res.data.data.roomIdx !== roomIdx) {
          navigate("/logout");
        }
      });
      //예제 시간이 아닐 경우 캠 저장 포기
      if (examSubStatusRef.current === 1) {
        //포기
        startCamRecordingHandler(videoStream);
      } else {
        recorder_video?.stopRecording(postFilesVideo);
      }
      startScreenRecordingHandler(screenStream);
      setLoading(false);
    } catch {
      startScreenRecordingHandler(screenStream);
      setLoading("error");
    }
  };

  const startScreenRecordingHandler = async (screenStream) => {
    if (screenStream?.active) {
      recorder_screen = await RecordRTC(screenStream, {
        type: "video",
        disableLogs: true,
      });

      await recorder_screen?.startRecording();
    }
  };

  const startCamRecordingHandler = async (videoStream) => {
    recordTitle = nowExamData.example
      ? nowExamData.example?.examSubName ?? "검사 전"
      : nowExamData.exam?.examSubName ?? "검사 전";

    if (videoStream?.active) {
      recorder_video = await RecordRTC(videoStream, {
        type: "video",
        disableLogs: true,
        videoBitsPerSecond: 10000,
      });

      await recorder_video?.startRecording();
    }
  };

  /** 녹화 중지 */
  const stopRecordHandler = async (sendAll = false) => {
    if (screenShareUseYN === "Y") {
      if (sendAll) {
        await recorder_screen?.stopRecording(sendAllScreenWabCamFile);
      } else {
        await recorder_screen?.stopRecording(sendScreenWebCamFile);
      }
    }
  };

  const changeDeviceStartRecord = async () => {
    //녹화 중지, 녹화 전송
    await recorder_video?.stopRecording(sendVideoFile);
    //캠 바꿈
    getWebcam(async (stream) => {
      await startCamRecordingHandler(stream);
      dispatch({ type: SET_DEVICE_CAM_STREAM, data: stream });
      return stream;
    });
  };

  /** 녹화 시작 */
  const startRecord = async () => {
    if (recorder_screen) {
      await stopRecordHandler();
    } else {
      startCamRecordingHandler(videoStream);
      startScreenRecordingHandler(screenStream);
    }
  };

  return {
    startRecord,
    stopRecordHandler,
    changeDeviceStartRecord,
    loading,
    setLoading,
  };
};
